<template>
    <div class="content md" style="position: relative">
        <calendar lunar @month="val => current = val" style="background-color: transparent">
            <template v-slot:full="{day}">
                <div class="date-buddha-box">
                    <div class="buddha-date-info">
                        <div class="buddha-date-num">{{day.day}}</div>
                        <div class="buddha-date-lunar">{{day.lunar.lnongDate == "初一" ? `${day.lunar.lnongMonth}月` : day.lunar.lnongDate}}</div>
                    </div>
                    <div class="date-buddha" v-if="dateMap[day.dateStr]" @click.stop>
                        <div class="date-buddha-item" v-for="(b, i) in dateMap[day.dateStr]" :key="i">
                            <a-popover trigger="click" v-if="b.cat == 1">
                                <div class="pop-buddha-name" slot="title">
                                    <span>{{b.name}}</span>
                                    <div class="txt-btn" @click="$router.push({name: 'BuddhismDetail', params: {id: b.id}})">查看详情<a-icon type="right" /></div>
                                </div>
                                <div class="date-buddha-ctx" slot="content">
                                    <div class="date-buddha-info">具体时间：{{b.timeText}}</div>
                                    <div class="date-buddha-info">殿堂：{{b.palace || '未指定'}}</div>
                                    <div class="date-buddha-info">状态：{{b.statusText}}</div>
                                    <div class="date-buddha-info" v-if="b.user_id">经手人：<open-data type="userName" :openid="b.user_id" /></div>
                                </div>
                                <div class="date-buddha-name" :style="{backgroundColor: b.status == 6 ? '#ccc' : colors[i % (colors.length - 1)]}">
                                    {{b.name}}
                                    <a-icon :type="b.icon" class="date-buddha-state" v-if="b.icon != 'circle'" />
                                    <div class="buddha-state-circle" v-else-if="b.icon"></div>
                                </div>
                            </a-popover>
                            <div class="date-buddha-name" :style="{backgroundColor: colors[i % (colors.length - 1)]}" v-else>{{b.name}}</div>
                        </div>
                    </div>
                </div>
            </template>
        </calendar>
        <a-button type="primary" class="export-buddhism" @click="exportData" :loading="exporting" v-if="admin">{{exporting ? '正在导出' : '本月佛事预订导出'}}</a-button>
    </div>
</template>

<script>
import calendar from "../../components/calendar/calendar";
import {getFhList, getMonthBuddhismList, getMonthBuddhismMap, buddhismColors} from "../../common/buddha/buddhism";
import {downLoadBlobFile} from "../../common/js/tool";
import {isBuddhaAdmin} from "../../common/constant/org";

export default {
    name: "BuddhaBoard",
    components: {
        calendar
    },
    data() {
        return {
            current: null,
            pujaList: null,
            buddhaList: null,
            colors: buddhismColors,
            dateMap: {},
            exporting: false,
            admin: isBuddhaAdmin()
        }
    },
    watch: {
        current() {
            this.getMonthBuddhismList();
        },
    },
    created() {
        this.getPujaList();
    },
    methods: {
        exportData() {
            if(this.exporting) return;
            this.exporting = true;
            let fileName = "佛事数据";
            const date = new Date(this.current);
            date.setDate(1);
            const start = date.pattern("yyyy-MM-dd");
            date.setMonth(date.getMonth() + 1);
            date.setDate(date.getDate() - 1);
            const end = date.pattern("yyyy-MM-dd");
            let url = `/admin/fs/export?begin_time=${start}&end_time=${end}`;
            this.$axios(url, {noTempleFilter: true, responseType: 'blob'}).then(res => {
                this.exporting = false;
                downLoadBlobFile(res, fileName);
            }).catch(() => {
                this.exporting = false;
            });
        },
        getPujaList() {
            getFhList().then(list => {
                this.pujaList = list;
                this.generateCalendarView();
            })
        },
        getMonthBuddhismList() {
            if(!this.current) return;
            getMonthBuddhismList(this.current).then(list => {
                this.buddhaList = list;
                this.generateCalendarView()
            })
        },
        generateCalendarView() {
            if(this.pujaList && this.buddhaList) {
                this.dateMap = getMonthBuddhismMap(this.current, this.pujaList, this.buddhaList);
            }
        }
    }
}
</script>

<style scoped lang="less">
    .export-buddhism {
        position: absolute;
        top: @padding-md;
        right: @padding-md;
        z-index: 1;
    }
    .date-buddha-box {
        padding: 6px 12px;
        min-height: 100px;
    }
    .buddha-date-info {
        display: flex;
        justify-content: space-between;
    }
    .date-buddha {
        margin-top: 8px;
    }
    .date-buddha-item {
        color: @white;
        &:not(:first-of-type) {
            margin-top: 4px;
        }
    }
    .date-buddha-name {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
    }
    .date-buddha-state {
        margin-left: .5em;
    }
    .buddha-state-circle {
        margin-left: .5em;
        width: 1em;
        height: 1em;
        border-radius: 1em;
        border: 1px solid currentColor;
    }
    .date-buddha-ctx {
        font-size: 12px;
        .txt-btn {
            text-align: center;
        }
    }
    .date-buddha-info {
        margin-bottom: 6px;
    }
    .pop-buddha-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 0;
        .txt-btn {
            font-size: 12px;
        }
    }
</style>
