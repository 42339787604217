import { render, staticRenderFns } from "./BuddhismEnrollList.vue?vue&type=template&id=56579ee0&scoped=true"
import script from "./BuddhismEnrollList.vue?vue&type=script&lang=js"
export * from "./BuddhismEnrollList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56579ee0",
  null
  
)

export default component.exports